import React, { useContext, useEffect } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { Button, DatePicker } from 'antd'
import { observer } from 'mobx-react'
import { MyTimeContext } from '../../contexts/my-time-context'
import { MembersTimeContext } from '../../contexts/members-time-context'
import { setDateInfo } from '../../utils'
import { themeColor } from '../../models/value-objects/theme-color'

const StyledDateNavi = styled.div`
    width: 100%;
    max-width: 500px;
    margin: 8px 0 8px 0;
    align-self: flex-end;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
`

const StyledDateNaviButtonContainer = styled.div`
    width: 100%;
    flex-grow: 1;
    flex-basis: 1px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

const StyledDatePicker = styled(DatePicker)`
    margin: 0 4px 0 4px;

    @media (max-width: 500px) {
        width: 80%;
    }
`

const StyledButton = styled(Button)`
    width: 30%;
    background-color: #ffffff;
    border: 1px solid #d9d9d9;
    outline: none;

    &:hover {
        background-color: ${themeColor.LIGHTER};
        color: #000000;
    }
`

const StyledTodayButton = styled(StyledButton)`
    margin: 0 0 0 4px;
    @media (max-width: 500px) {
        width: 100%;
        margin: 4px 0 0 0;
    }
`

export const DateNavi = observer(() => {
    const myTimeStore = useContext(MyTimeContext)
    const membersTimeStore = useContext(MembersTimeContext)

    useEffect(() => {
        setDateInfo(myTimeStore.currentDate, myTimeStore, membersTimeStore)
    }, [myTimeStore, membersTimeStore])

    return (
        <StyledDateNavi>
            <StyledDateNaviButtonContainer>
                <StyledButton
                    onClick={() => {
                        const prevWeek = moment(
                            myTimeStore.currentDate,
                        ).subtract(7, 'd')

                        setDateInfo(
                            prevWeek.toDate(),
                            myTimeStore,
                            membersTimeStore,
                        )
                    }}
                >
                    {'<'}
                </StyledButton>
                <StyledDatePicker
                    value={moment(myTimeStore.currentDate)}
                    onChange={(date: moment.Moment | null) => {
                        if (date !== null) {
                            setDateInfo(
                                moment(date).toDate(),
                                myTimeStore,
                                membersTimeStore,
                            )
                        } else {
                            setDateInfo(
                                moment(new Date()).toDate(),
                                myTimeStore,
                                membersTimeStore,
                            )
                        }
                    }}
                />
                <StyledButton
                    onClick={() => {
                        const nextWeek = moment(myTimeStore.currentDate).add(
                            7,
                            'd',
                        )

                        setDateInfo(
                            nextWeek.toDate(),
                            myTimeStore,
                            membersTimeStore,
                        )
                    }}
                >
                    {'>'}
                </StyledButton>
            </StyledDateNaviButtonContainer>
            <StyledTodayButton
                onClick={() => {
                    setDateInfo(new Date(), myTimeStore, membersTimeStore)
                }}
            >
                today
            </StyledTodayButton>
        </StyledDateNavi>
    )
})
