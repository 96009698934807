import React, { useContext } from 'react'
import { MemberInfoContext } from '../../contexts/member-info-context'
import { NameInfo } from './NameInfo'

export const NameColumns = (userId: number) => {
    const memberInfoStore = useContext(MemberInfoContext)
    let memberIndex = memberInfoStore.allMemberInfoList.findIndex(
        data => data.id === userId,
    )

    return (
        <>
            {memberIndex === -1 &&
                userId > 0 &&
                NameInfo('U', 'Unknown', '1300px')}
            {memberIndex === -1 && userId < 0 && NameInfo('T', 'Total', '0px')}
            {memberIndex >= 0 &&
                NameInfo(
                    memberInfoStore.allMemberInfoList[memberIndex].info1,
                    memberInfoStore.allMemberInfoList[memberIndex].name,
                    '1300px',
                )}
        </>
    )
}
