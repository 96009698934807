import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { DatePicker, AutoComplete, Button } from 'antd'
import { observer } from 'mobx-react'
import { ProjectsInfoContext } from '../../contexts/projects-info-context'
import { isInOptions, changeToExactCode } from '../../utils'
import moment from 'moment'
import { ProjectsInfo } from '../../models/view-models/projects-info-view-model'
import { themeColor } from '../../models/value-objects/theme-color'

const StyledSummaryNavi = styled.div`
    width: 100%;
    max-width: 500px;
    height: fit-content;
    margin: 8px 0 8px 0;
    align-self: flex-end;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
`

const StyledAutoComplete = styled(AutoComplete)`
    width: 20%;
    @media (max-width: 500px) {
        width: 100%;
    }
`

const StyledDatePicker = styled(DatePicker)`
    width: calc(30% - 4px);
    margin: 0 0 0 4px;
    @media (max-width: 500px) {
        width: calc(50% - 2px);
        margin: 4px 0 0 0;
    }
`

const StyledButton = styled(Button)`
    width: calc(20% - 4px);
    margin: 0 0 0 4px;
    background-color: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    outline: none;

    &:hover {
        background-color: ${themeColor.LIGHTER};
        color: #000000;
    }

    @media (max-width: 500px) {
        width: 100%;
        margin: 4px 0 0 0;
    }
`

const searchProjectSummary = (
    projectsInfoStore: ProjectsInfo,
    code: string,
    dateStart: string,
    dateEnd: string,
) => {
    if (isInOptions(projectsInfoStore.projectsList, code)) {
        projectsInfoStore.getProjectPercent(code, dateStart, dateEnd)
    } else {
        if (code === '') {
            alert('code를 입력해주세요.')
        } else {
            alert('code를 정확히 입력해주세요.')
        }
    }
}

export const SummaryNavi = observer(() => {
    const projectsInfoStore = useContext(ProjectsInfoContext)
    const today: string = moment(new Date()).format('YYYY-MM-DD')
    const [code, setCode] = useState(
        projectsInfoStore.projectPercent?.project ?? '',
    )
    const [dateStart, setDateStart] = useState('2013-01-01')
    const [dateEnd, setDateEnd] = useState(today)

    return (
        <StyledSummaryNavi>
            <StyledAutoComplete
                placeholder="code"
                value={changeToExactCode(projectsInfoStore.projectsList, code)}
                options={projectsInfoStore.projectsList}
                filterOption={(inputValue: string, option: any) =>
                    option.value
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                }
                onChange={(input: string) => {
                    setCode(input)
                }}
                onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                    if (e.keyCode === 13) {
                        if (isInOptions(projectsInfoStore.projectsList, code)) {
                            projectsInfoStore.getProjectPercent(
                                code,
                                dateStart,
                                dateEnd,
                            )
                        }
                    }
                }}
                onSelect={(value: string) => {
                    setCode(value)
                    projectsInfoStore.getProjectPercent(
                        value,
                        dateStart,
                        dateEnd,
                    )
                }}
                notFoundContent="no match"
            />
            <StyledDatePicker
                placeholder="start"
                value={moment(dateStart)}
                onChange={(date: moment.Moment | null) => {
                    if (date !== null) {
                        setDateStart(date.format('YYYY-MM-DD'))
                    } else {
                        setDateStart('2013-01-01')
                    }
                }}
            />
            <StyledDatePicker
                style={{ marginLeft: '4px' }}
                placeholder="end"
                value={moment(dateEnd)}
                onChange={(date: moment.Moment | null) => {
                    if (date !== null) {
                        setDateEnd(date.format('YYYY-MM-DD'))
                    } else {
                        setDateEnd(today)
                    }
                }}
            />
            <StyledButton
                onClick={() => {
                    searchProjectSummary(
                        projectsInfoStore,
                        code,
                        dateStart,
                        dateEnd,
                    )
                }}
            >
                검색
            </StyledButton>
        </StyledSummaryNavi>
    )
})
