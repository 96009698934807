import axios from 'axios'

export const httpClient = axios.create({
    baseURL: '/api/',
})

httpClient.interceptors.request.use(
    config => {
        const token = localStorage.getItem('token')
        if (token) {
            config.headers.Authorization = token
        }
        return config
    },
    error => {
        return Promise.reject(error)
    },
)

httpClient.interceptors.response.use(
    response => {
        return response
    },
    error => {
        console.log(error.response.status)
        if (error.response.status === 401) {
            console.log('logout')
            localStorage.clear()
            window.location.href = '/login'
        }
        return Promise.reject(error)
    },
)
