import { httpClient } from './http-client'

export class MembersTimeApis {
    static getMembersTime = (dateStart: string, dateEnd: string) => {
        return httpClient.get('mytime/all', {
            params: {
                dateStart,
                dateEnd,
            },
        })
    }
}
