import { MembersTimeApis } from '../../apis/members-time-apis'
import moment from 'moment'
import { observable, action } from 'mobx'
import { asyncAction } from 'mobx-utils'
import { TableCell } from '../../views/components/TableCell'
import { ITimeInfo } from '../entities/time-info'
import { ColumnProps } from 'antd/lib/table'
import { delay } from '../../utils/index'
import { CurrentInfo } from './current-info-view-model'
import { IUserInfo } from '../entities/user-info'
import { NameInfo } from '../../views/fragments/NameInfo'

export class MembersTime extends CurrentInfo {
    @observable weeklyTableState: 'loading' | 'done' | 'error' = 'loading'
    @observable errorMessage: string = ''

    @observable columns: ColumnProps<object>[] = []
    @observable tableDataList: any[] = []

    @asyncAction public *getMembersTime(memberInfoList: IUserInfo[]) {
        this.weeklyTableState = 'loading'
        yield this.setColumns()
        try {
            const monday = this.weeklyDateList[0]
            const sunday = this.weeklyDateList[6]
            const res = yield MembersTimeApis.getMembersTime(monday, sunday)
            this.setTableDataList(res.data, memberInfoList)

            delay().then(result => {
                if (result === 'done') this.weeklyTableState = 'done'
            })
        } catch (e) {
            this.errorMessage = e.message
            this.weeklyTableState = 'error'
        }
    }

    @action public setColumns() {
        this.columns = [
            {
                title: '',
                dataIndex: 'member',
                width: '12%',
                fixed: true,
                render: (userInfo: IUserInfo) =>
                    NameInfo(userInfo.info1, userInfo.name, '1300px'),
            },
        ]
        let weeklyIndex = 0

        while (weeklyIndex < 7) {
            this.columns.push({
                title: moment(this.weeklyDateList[weeklyIndex]).format(
                    'MM/DD dd',
                ),
                dataIndex: this.weeklyDateList[weeklyIndex],
                render: (projectList: ITimeInfo[]) => TableCell(projectList),
            })
            weeklyIndex++
        }
    }

    @action public async setTableDataList(
        res: ITimeInfo[],
        memberInfoList: IUserInfo[],
    ) {
        this.tableDataList = []
        let resIndex = 0
        let _temp: any = {}
        memberInfoList.forEach(data => {
            _temp = this.resetDataByUserId(data)
            while (resIndex < res.length && res[resIndex].userId === data.id) {
                _temp[res[resIndex].dateTime].push(res[resIndex])
                resIndex++
            }
            this.tableDataList.push(_temp)
        })
    }

    public resetDataByUserId(memberInfo: IUserInfo) {
        const dataByUserId: any = {}
        let weeklyIndex = 0

        dataByUserId.key = memberInfo.id
        dataByUserId.member = memberInfo

        while (weeklyIndex < 7) {
            dataByUserId[this.weeklyDateList[weeklyIndex]] = []
            weeklyIndex++
        }

        return dataByUserId
    }
}
