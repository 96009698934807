import React, { useContext, useEffect } from 'react'
import styled from 'styled-components'
import { Table } from 'antd'
import { observer } from 'mobx-react'
import { MembersTimeContext } from '../../contexts/members-time-context'
import { LoadingState } from '../components/LoadingState'
import { ErrorState } from '../components/ErrorState'
import { MemberInfoContext } from '../../contexts/member-info-context'

const StyledWeeklyTable = styled.div`
    width: 100%;
    min-height: 500px;
    border: 1px solid #d9d9d9;
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    overflow-y: auto;
`

const StyledTable = styled(Table)`
    min-width: 800px;
    height: 100%;
    td {
        padding: 6px !important;
    }
    .ant-table-cell {
        vertical-align: top;
    }
`

export const WeeklyTable = observer(() => {
    const membersTimeStore = useContext(MembersTimeContext)
    const memberInfoStore = useContext(MemberInfoContext)

    useEffect(() => {
        membersTimeStore.getMembersTime(memberInfoStore.usedMemberInfoList)
    }, [
        membersTimeStore,
        memberInfoStore,
        membersTimeStore.weeklyDateList,
        memberInfoStore.usedMemberInfoList,
    ])

    return (
        <StyledWeeklyTable>
            {membersTimeStore.weeklyTableState === 'loading' && (
                <LoadingState />
            )}
            {membersTimeStore.weeklyTableState === 'done' && (
                <StyledTable
                    dataSource={membersTimeStore.tableDataList}
                    columns={membersTimeStore.columns}
                    tableLayout="fixed"
                    bordered={true}
                    pagination={false}
                />
            )}
            {membersTimeStore.weeklyTableState === 'error' && (
                <ErrorState errorMessage={membersTimeStore.errorMessage} />
            )}
        </StyledWeeklyTable>
    )
})
