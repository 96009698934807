import React from 'react'
import { ITimeInfo } from '../../models/entities/time-info'
import styled from 'styled-components'
import { checkProjectInfoColor } from '../../utils/index'

const StyledTableCell = styled.div`
    display: flex;
    flex-direction: column;
`

const StyledTableCellItem = styled(({ color: string, ...props }) => (
    <div {...props} />
))`
    padding: 0 4px 0 4px;
    color: ${props => props.color};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`

const StyledProjectName = styled.span`
    margin-right: 2px;
`

export const TableCell = (projectList: ITimeInfo[]) => {
    return projectList ? (
        <StyledTableCell>
            {projectList.map((project: ITimeInfo) => {
                const title =
                    project.note || `${project.project} ${project.percent}%`
                return (
                    <StyledTableCellItem
                        key={title}
                        color={checkProjectInfoColor(project.project)}
                    >
                        <StyledProjectName>
                            {project.note || project.project}
                        </StyledProjectName>
                        <span>{project.percent || null}</span>
                    </StyledTableCellItem>
                )
            })}
        </StyledTableCell>
    ) : null
}
