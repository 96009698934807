import React from 'react'
import styled from 'styled-components'
import moment from 'moment'

const StyledDateBlockHeader = styled.div`
    width: 100%;
    margin-bottom: 4px;
    border-bottom: 1px solid #6d6d6d;
    padding: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
`

const StyledDateInfo = styled(({ fontColor: string, ...props }) => (
    <span {...props} />
))`
    color: ${props => props.fontColor};
`

const getFontColor = (date: string) => {
    const day = moment(date).day()

    if (day === 6) {
        // 토요일인 경우
        return '#78b0ff' // 연파랑
    } else if (day === 0) {
        // 일요일인 경우
        return '#ff8578' // 연빨강
    } else {
        return '#6d6d6d' // 연회색
    }
}

interface IProps {
    date: string
}

export const DateBlockHeader = (props: IProps) => {
    return (
        <StyledDateBlockHeader>
            <div>
                <span>{`${moment(props.date).format('MM/DD')} `}</span>
                <StyledDateInfo fontColor={getFontColor(props.date)}>
                    {moment(props.date).format('dd')}
                </StyledDateInfo>
            </div>
        </StyledDateBlockHeader>
    )
}
