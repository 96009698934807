import { MyTimeApis } from '../../apis/my-time-apis'
import { observable, action } from 'mobx'
import { asyncAction } from 'mobx-utils'
import { delay } from '../../utils/index'
import { PieDatum } from '@nivo/pie'
import { CalendarDatum } from '@nivo/calendar'
import { IDateList } from '../value-objects/date-list'
import { ITimeInfo } from '../entities/time-info'
import { CurrentInfo } from './current-info-view-model'
import moment from 'moment'

export class MyTime extends CurrentInfo {
    @observable weeklyChartState: 'loading' | 'done' | 'error' = 'loading'
    @observable pieChartState: 'loading' | 'done' | 'error' = 'loading'
    @observable calendarChartState: 'loading' | 'done' | 'error' = 'loading'
    @observable errorMessage: string = ''

    @observable dateList: IDateList[] = []

    @observable totalPercent: number = 0
    @observable percentByProject: Map<string, number> = new Map()
    @observable pieChartDataset: PieDatum[] = []

    @observable calendarChartDataset: CalendarDatum[] = []

    @asyncAction public *getMyWeeklyTime() {
        try {
            const monday = moment(this.weeklyDateList[0]).format('YYYY-MM-DD')
            const sunday = moment(this.weeklyDateList[6]).format('YYYY-MM-DD')

            const res = yield MyTimeApis.getMyTime(monday, sunday)

            this.setDateList(res.data)
            this.setPercentByProject(res.data)

            delay().then(result => {
                if (result === 'done') {
                    this.weeklyChartState = 'done'
                    this.pieChartState = 'done'
                }
            })
        } catch (e) {
            this.errorMessage = e.message
            this.weeklyChartState = 'error'
            this.pieChartState = 'error'
        }
    }

    @action public setDateList(res: ITimeInfo[]) {
        this.dateList = []
        let resIndex: number = 0
        let _temp: IDateList[] = []

        this.weeklyDateList.forEach((date: string, i: number) => {
            _temp[i] = {
                date: date,
                projectInfo: [],
            }
            while (resIndex < res.length) {
                if (date === res[resIndex].dateTime) {
                    _temp[i].projectInfo.push(res[resIndex])
                    resIndex++
                } else {
                    break
                }
            }
        })
        this.dateList = _temp
    }

    @action public setPercentByProject(res: ITimeInfo[]) {
        this.totalPercent = 0
        this.percentByProject.clear()
        res.forEach((data: ITimeInfo) => {
            if (data.project !== 'NOTE') {
                this.totalPercent += data.percent

                const preValue = this.percentByProject.get(data.project) ?? 0
                this.percentByProject.set(data.project, preValue + data.percent)
            }
        })

        this.setPieChartDataset()
    }

    @action public setPieChartDataset() {
        this.pieChartDataset = []
        this.percentByProject.forEach((value: number, key: string) => {
            this.pieChartDataset.push({ id: key, value: value, label: key })
        })
    }

    @asyncAction public *getMyMonthlyTime() {
        const firstDate = moment(this.currentDate)
            .startOf('year')
            .format('YYYY-MM-DD')
        const lastDate = moment(this.currentDate)
            .endOf('year')
            .format('YYYY-MM-DD')
        try {
            const res = yield MyTimeApis.getMyTime(firstDate, lastDate)

            if (res.data.length > 0) {
                this.setCalendarChartDataset(res.data)
            }

            delay().then(result => {
                if (result === 'done') this.calendarChartState = 'done'
            })
        } catch (e) {
            this.errorMessage = e.message
            this.calendarChartState = 'error'
        }
    }

    @action public setCalendarChartDataset(res: ITimeInfo[]) {
        this.calendarChartDataset = []
        let _temp: CalendarDatum = {
            day: res[0].dateTime,
            value: 0,
        }

        res.forEach((data: ITimeInfo) => {
            if (data.dateTime !== _temp.day) {
                this.calendarChartDataset.push(_temp)
                _temp = {
                    day: data.dateTime,
                    value: 0,
                }
            }
            _temp.value += data.percent
        })
        this.calendarChartDataset.push(_temp)
    }

    @asyncAction public *postMyTime(projectInfo: ITimeInfo) {
        try {
            const res = yield MyTimeApis.postMyTime(projectInfo)
            if (res.data.result === 'fail') {
                window.alert('Fail to post.')
                this.weeklyChartState = 'loading'
                this.getMyWeeklyTime()
            } else {
                this.getMyWeeklyTime()
                this.getMyMonthlyTime()
            }
        } catch (e) {
            window.alert('Fail to post.')
            console.log(e.message)
            this.getMyWeeklyTime()
        }
    }

    @asyncAction public *putMyTime(projectInfo: ITimeInfo) {
        try {
            const res = yield MyTimeApis.putMyTime(projectInfo)
            if (res.data.result === 'fail') {
                window.alert('Fail to put.')
                this.weeklyChartState = 'loading'
                this.getMyWeeklyTime()
            } else {
                this.getMyWeeklyTime()
                this.getMyMonthlyTime()
            }
        } catch (e) {
            window.alert('Fail to put.')
            console.log(e.message)
            this.getMyWeeklyTime()
        }
    }

    @asyncAction public *deleteMyTime(projectInfo: ITimeInfo) {
        try {
            const res = yield MyTimeApis.deleteMyTime(projectInfo)
            if (res.data.result === 'fail') {
                window.alert('Fail to delete.')
                this.weeklyChartState = 'loading'
                this.getMyWeeklyTime()
            } else {
                this.getMyWeeklyTime()
                this.getMyMonthlyTime()
            }
        } catch (e) {
            window.alert('Fail to delete.')
            console.log(e.message)
            this.getMyWeeklyTime()
        }
    }
}
