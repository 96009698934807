class ThemeColor {
    colorValue = Math.round((Math.random() * 1000) % 260)

    NORMAL: string = `hsl(${this.colorValue}, 70%, 80%)`
    HIGHLIGHT: string = `hsl(${this.colorValue}, 85%, 70%)`
    LIGHTER: string = `hsl(${this.colorValue}, 80%, 95%)`
    DARKER: string = `hsl(${this.colorValue}, 25%, 95%)`
    BACKGROUND: string = `hsl(${this.colorValue}, 25%, 60%)`
    TEXT: string = `hsl(${this.colorValue}, 80%, 50%)`
}

export const themeColor = new ThemeColor()
