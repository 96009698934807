import React from 'react'
import styled from 'styled-components'
import logo from '../../jelly_logo.png'
import { themeColor } from '../../models/value-objects/theme-color'

const StyledLogo = styled.div`
    display: flex;
    flex-direction: row;
`

const StyledImageWrapper = styled.div`
    background-color: ${themeColor.NORMAL};
    margin: 0 10px 0 10px;
`

const StyledImage = styled(({ height: string, ...props }) => (
    <img alt="jelly" {...props} />
))`
    height: ${props => props.height};
`

interface IProps {
    height: string
}

export const Logo = (props: IProps) => {
    return (
        <StyledLogo>
            <StyledImageWrapper>
                <StyledImage height={props.height} src={logo} />
            </StyledImageWrapper>
        </StyledLogo>
    )
}
