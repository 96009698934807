import React, { useContext } from 'react'
import styled from 'styled-components'
import { UserInfoContext } from '../../contexts/user-info-context'
import { observer } from 'mobx-react'
import { Logo } from '../components/Logo'
import { LoginForm } from '../fragments/LoginForm'

const StyledLoginPage = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const StyledFailMessage = styled.h3`
    color: #d40f0f;
`

export const LoginPage = observer(() => {
    const userInfoStore = useContext(UserInfoContext)

    return (
        <StyledLoginPage>
            <Logo height="100px" />
            <StyledFailMessage>{userInfoStore.failMessage}</StyledFailMessage>
            <LoginForm />
        </StyledLoginPage>
    )
})
