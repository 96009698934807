import React, { useContext, useEffect } from 'react'
import { observer } from 'mobx-react'
import styled from 'styled-components'
import { WeeklyChart } from '../fragments/WeeklyChart'
import { UserInfoContext } from '../../contexts/user-info-context'
import { PieChart } from '../fragments/PieChart'
import { CalendarChart } from '../fragments/CalendarChart'
import { DateNavi } from '../fragments/DateNavi'

const StyledChartSection = styled.section`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
`

export const MyProjectPage = observer(() => {
    const userInfoStore = useContext(UserInfoContext)

    useEffect(() => {
        userInfoStore.setSelectedNavi('MyProject')
    }, [userInfoStore])

    return (
        <>
            <DateNavi />
            <WeeklyChart />
            <StyledChartSection>
                <PieChart />
                <CalendarChart />
            </StyledChartSection>
        </>
    )
})
