import React, { useContext, useEffect } from 'react'
import styled from 'styled-components'
import { Header } from '../fragments/Header'
import { Navi } from '../fragments/Navi'
import { Switch } from 'react-router-dom'
import { MembersProjectPage } from './MembersProjectPage'
import { MyProjectPage } from './MyProjectPage'
import { observer } from 'mobx-react'
import { MemberInfoContext } from '../../contexts/member-info-context'
import { PrivateRoute } from '../fragments/PrivateRoute'
import { ProjectSummaryPage } from './ProjectSummaryPage'
import { ProjectsInfoContext } from '../../contexts/projects-info-context'

const StyledMain = styled.main`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`

const StyledPage = styled.div`
    width: calc(100% - 180px);
    height: calc(100% - 60px);
    background-color: #ffffff;
    margin-top: 60px;
    padding: 0 16px 0 16px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 1300px) {
        width: calc(100% - 54px);
    }
`

export const HomePage = observer(() => {
    const memberInfoStore = useContext(MemberInfoContext)
    const projectsInfoStore = useContext(ProjectsInfoContext)

    useEffect(() => {
        memberInfoStore.getAllUser()
        projectsInfoStore.getAllProjects()
    }, [memberInfoStore, projectsInfoStore])

    return (
        <>
            <Header />
            <StyledMain>
                <Navi />
                <StyledPage>
                    <Switch>
                        <PrivateRoute
                            path="/members-project"
                            component={MembersProjectPage}
                        />
                        <PrivateRoute
                            path="/project-summary"
                            component={ProjectSummaryPage}
                        />
                        <PrivateRoute path="/" component={MyProjectPage} />
                    </Switch>
                </StyledPage>
            </StyledMain>
        </>
    )
})
