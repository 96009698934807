import { TimeColor } from '../models/value-objects/time-color'
import { ITimeInfo } from '../models/entities/time-info'
import { MyTime } from '../models/view-models/my-time-view-model'
import { MembersTime } from '../models/view-models/members-time-view-model'
import { ProjectsInfo } from '../models/view-models/projects-info-view-model'

const PROJECT_EDIT_COUNT = 4

export const checkProjectInfoColor = (project: string) => {
    if (project === 'NOTE') {
        return TimeColor.NOTE
    } else {
        if (~project.indexOf('(') && ~project.indexOf(')'))
            return TimeColor.TEMPORARY
        else return TimeColor.OFFICIAL
    }
}

export const delay = () => {
    return new Promise(resolve => {
        setTimeout(() => {
            resolve('done')
        }, 30)
    })
}

export const setInputList = (date: string, projectInfoList: ITimeInfo[]) => {
    const inputList: any[] = []
    const id = localStorage.getItem('id')

    for (let i = 0; i < PROJECT_EDIT_COUNT; i++) {
        const defaultProjectInfo = {
            dateTime: date,
            note: '',
            ordinal: i + 1,
            percent: 0,
            project: '',
            userId: id,
        }
        inputList.push(defaultProjectInfo)
    }

    for (let i = 0; i < projectInfoList.length; i++) {
        inputList[projectInfoList[i].ordinal - 1] = projectInfoList[i]
    }

    return inputList
}

export const setDateInfo = (
    date: Date,
    myTime: MyTime,
    membersTime: MembersTime,
) => {
    myTime.setCurrentDateInfo(date)
    membersTime.setCurrentDateInfo(date)
}

export const isInOptions = (options: any[], value: string) => {
    return options.some(
        option => option.value.toUpperCase() === value.toUpperCase(),
    )
}

export const isTemporaryProject = (value: string) => {
    return value.includes('(') && value.includes(')')
}

export const changeToExactCode = (options: any[], value: string) => {
    let returnValue: string = value
    options.forEach(option => {
        if (option.value.toUpperCase() === value.toUpperCase())
            returnValue = option.value
    })

    return returnValue
}

export const checkInputValueType = (
    projectsInfoStore: ProjectsInfo,
    inputValue: string,
    projectInfo: ITimeInfo,
) => {
    const inputValues = inputValue.split(' ')
    const isProject =
        isInOptions(projectsInfoStore.projectsList, inputValues[0]) ||
        isTemporaryProject(inputValues[0])
    const isPercent =
        !isNaN(Number(inputValues[1])) && Number(inputValues[1]) > 0

    if (isProject && isPercent) {
        const project = changeToExactCode(
            projectsInfoStore.projectsList,
            inputValues[0],
        )
        let percent = Number(inputValues[1])
        if (percent > 200) {
            percent = 200
        }

        projectInfo.project = project
        projectInfo.note = ''
        projectInfo.percent = percent

        return `${project} ${percent}`
    } else {
        if (inputValue === '') {
            projectInfo.project = ''
            projectInfo.note = ''
            projectInfo.percent = 0

            return ''
        } else {
            projectInfo.project = 'NOTE'
            projectInfo.note = inputValue
            projectInfo.percent = 0

            return inputValue
        }
    }
}
