import React, { useContext, useEffect } from 'react'
import { observer } from 'mobx-react'
import { UserInfoContext } from '../../contexts/user-info-context'
import { WeeklyTable } from '../fragments/WeeklyTable'
import { DateNavi } from '../fragments/DateNavi'

export const MembersProjectPage = observer(() => {
    const userInfoStore = useContext(UserInfoContext)

    useEffect(() => {
        userInfoStore.setSelectedNavi('MembersProject')
    }, [userInfoStore])

    return (
        <>
            <DateNavi />
            <WeeklyTable />
            <DateNavi />
        </>
    )
})
