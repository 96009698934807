import React, { useContext } from 'react'
import styled from 'styled-components'
import { Table } from 'antd'
import { observer } from 'mobx-react'
import { LoadingState } from '../components/LoadingState'
import { ErrorState } from '../components/ErrorState'
import { ProjectsInfoContext } from '../../contexts/projects-info-context'

const StyledWeeklyTable = styled.div`
    width: 100%;
    margin-bottom: 16px;
    border: 1px solid #d9d9d9;
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    overflow-y: auto;
`

const StyledTable = styled(Table)`
    min-width: 400px;
    height: 100%;
    .ant-table-cell {
        padding: 10px !important;
    }
    .ant-table-row.ant-table-row-level-0.total {
        font-weight: bold;
    }
`

export const SummaryTable = observer(() => {
    const projectsInfoStore = useContext(ProjectsInfoContext)

    return (
        <>
            {projectsInfoStore.state === 'loading' && <LoadingState />}
            {projectsInfoStore.state === 'done' && (
                <StyledWeeklyTable>
                    <StyledTable
                        dataSource={projectsInfoStore.summaryTableDataList}
                        columns={projectsInfoStore.summaryColumns}
                        tableLayout="fixed"
                        bordered={true}
                        pagination={false}
                        rowClassName={(record: any) => {
                            return record.userId === -1 ? 'total' : ''
                        }}
                    />
                </StyledWeeklyTable>
            )}
            {projectsInfoStore.state === 'error' && (
                <ErrorState errorMessage={projectsInfoStore.errorMessage} />
            )}
        </>
    )
})
