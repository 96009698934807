import React from 'react'
import { FrownOutlined } from '@ant-design/icons'
import styled from 'styled-components'

const StyledErrorState = styled.div`
    width: 100%;
    height: 100%;
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    span {
        font-size: 3rem;
    }
    div {
        height: fit-content;
        margin: 4px;
    }
`

interface IProps {
    errorMessage: string
}

export const ErrorState = (props: IProps) => {
    return (
        <StyledErrorState>
            <FrownOutlined /> <div>error! {props.errorMessage}</div>
        </StyledErrorState>
    )
}
