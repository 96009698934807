import React, { useContext } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { UserInfoContext } from '../../contexts/user-info-context'
import { Form, Input, Button } from 'antd'
import { observer } from 'mobx-react'
import { Store } from 'antd/lib/form/interface'
import { themeColor } from '../../models/value-objects/theme-color'

const StyledLoginForm = styled(Form)`
    width: 100%;
    max-width: 500px;
    height: 200px;
    padding: 0 20px 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

const StyledInput = styled(Input)`
    width: 100%;
    height: 50px;
`

const StyledInputPassword = styled(Input.Password)`
    width: 100%;
    height: 50px;
`

const StyledLoginButton = styled(Button)`
    width: 100%;
    height: 50px;
    background-color: ${themeColor.NORMAL};
    border: none;
    color: #ffffff;
    font-weight: bold;

    &:hover,
    &:focus {
        outline: none;
        background-color: ${themeColor.LIGHTER};
        color: #000000;
    }
`

export const LoginForm = observer(() => {
    const userInfoStore = useContext(UserInfoContext)
    const history = useHistory()
    const [form] = Form.useForm()

    return (
        <StyledLoginForm
            form={form}
            onFinish={async (values: Store) => {
                const isLogin = await userInfoStore.login(
                    values.account,
                    values.password,
                )
                if (isLogin) {
                    history.push('/')
                } else {
                    form.resetFields()
                }
            }}
        >
            <Form.Item
                name="account"
                rules={[
                    { required: true, message: 'Please input your username!' },
                ]}
            >
                <StyledInput
                    autoFocus={true}
                    autoComplete="off"
                    placeholder="account"
                />
            </Form.Item>
            <Form.Item
                name="password"
                rules={[
                    { required: true, message: 'Please input your password!' },
                ]}
            >
                <StyledInputPassword placeholder="password" />
            </Form.Item>
            <Form.Item>
                <StyledLoginButton htmlType="submit">Login</StyledLoginButton>
            </Form.Item>
        </StyledLoginForm>
    )
})
