import { httpClient } from './http-client'
import { ITimeInfo } from '../models/entities/time-info'

export class MyTimeApis {
    static getMyTime = (dateStart: string, dateEnd: string) => {
        return httpClient.get(`mytime/user/${localStorage.getItem('id')}`, {
            params: {
                dateStart,
                dateEnd,
            },
        })
    }

    static postMyTime = (projectInfo: ITimeInfo) => {
        return httpClient.post('mytime', projectInfo)
    }

    static putMyTime = (projectInfo: ITimeInfo) => {
        return httpClient.put('mytime', projectInfo)
    }

    static deleteMyTime = (projectInfo: ITimeInfo) => {
        return httpClient.delete('mytime', { data: projectInfo })
    }
}
