import { httpClient } from './http-client'

export class UserInfoApis {
    static login = (account: string, password: string) => {
        return httpClient.post(`login/${account}`, {
            account,
            password,
        })
    }

    static getUserInfo = () => {
        return httpClient.get(`user/${localStorage.getItem('account')}`)
    }
}
