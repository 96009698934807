import React, { useContext } from 'react'
import styled from 'styled-components'
import { ProjectsInfoContext } from '../../contexts/projects-info-context'
import { observer } from 'mobx-react'
import { changeToExactCode } from '../../utils'
import { themeColor } from '../../models/value-objects/theme-color'

const StyledSearchInfo = styled.div`
    width: 100%;
    height: fit-content;
    background-color: ${themeColor.DARKER};
    margin-bottom: 8px;
    border-radius: 2px;
    padding: 4px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
`

const StyledInfoBox = styled.div`
    margin: 4px 8px 4px 8px;
    span {
        min-width: 30px;
        font-weight: bold;
    }
`

export const SearchInfo = observer(() => {
    const projectsInfoStore = useContext(ProjectsInfoContext)

    return (
        <StyledSearchInfo>
            <StyledInfoBox>
                프로젝트 :{' '}
                <span>
                    {projectsInfoStore.projectPercent?.project
                        ? changeToExactCode(
                              projectsInfoStore.projectsList,
                              projectsInfoStore.projectPercent?.project,
                          )
                        : 'no data'}
                </span>
            </StyledInfoBox>
            <StyledInfoBox>
                기간 :{' '}
                <span>
                    {projectsInfoStore.projectPercent
                        ? `${projectsInfoStore.projectPercent.firstDate} ~ ${projectsInfoStore.projectPercent.lastDate}`
                        : 'no data'}
                </span>
            </StyledInfoBox>
        </StyledSearchInfo>
    )
})
