import { observable, action } from 'mobx'
import { UserInfoApis } from '../../apis/user-info-apis'
import { asyncAction } from 'mobx-utils'

export class UserInfo {
    @observable selectedNavi:
        | 'MyProject'
        | 'MembersProject'
        | 'ProjectSummary'
        | undefined
    @observable userInfo: any = {}
    @observable editingDate: string = ''
    @observable errorMessage: string = ''
    @observable failMessage: string = ''

    @action public setEditingDate(date: string) {
        this.editingDate = date
    }

    @action public setSelectedNavi(
        value: 'MyProject' | 'MembersProject' | 'ProjectSummary',
    ) {
        this.selectedNavi = value
    }

    @asyncAction public *login(account: string, password: string) {
        let isLogin: boolean = false
        try {
            const res = yield UserInfoApis.login(account, password)
            if (res.data.result === 'success') {
                isLogin = true
                this.errorMessage = ''
                this.failMessage = ''
                localStorage.setItem('token', res.data.authorization)
                localStorage.setItem('id', res.data.info.id)
                localStorage.setItem('account', res.data.info.account)
                this.userInfo = res.data.info
            } else {
                this.failMessage = 'Fail to login.'
            }
        } catch (e) {
            this.failMessage = 'Fail to login.'
            this.errorMessage = e.message
        }

        return isLogin
    }

    @asyncAction public *getUserInfo() {
        try {
            const res = yield UserInfoApis.getUserInfo()
            this.userInfo = res.data
        } catch (e) {
            this.errorMessage = e.message
        }
    }
}
