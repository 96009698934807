import { observable, action } from 'mobx'
import { asyncAction } from 'mobx-utils'
import { MemberInfoApis } from '../../apis/member-info-apis'
import { IUserInfo } from '../entities/user-info'

export class MemberInfo {
    @observable allMemberInfoList: IUserInfo[] = []
    @observable usedMemberInfoList: IUserInfo[] = []
    @observable errorMessage: string = ''

    @asyncAction public *getAllUser() {
        try {
            const res = yield MemberInfoApis.getAllUser()
            this.setAllMemberInfoList(res.data)
            this.setUsedMemberInfoList(res.data)
        } catch (e) {
            this.errorMessage = e.message
        }
    }

    @action public setAllMemberInfoList(res: IUserInfo[]) {
        this.allMemberInfoList = res
    }

    @action public setUsedMemberInfoList(res: IUserInfo[]) {
        this.usedMemberInfoList = []
        res.forEach(data => {
            if (data.group1 !== 'NotUsed') {
                this.usedMemberInfoList.push(data)
            }
        })
    }
}
