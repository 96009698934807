import React from 'react'
import styled from 'styled-components'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import 'antd/dist/antd.css'
import 'moment/locale/ko'
import { LoginPage } from './views/pages/LoginPage'
import { HomePage } from './views/pages/HomePage'
import { PrivateRoute } from './views/fragments/PrivateRoute'

const StyledApp = styled.div`
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    margin: 0;
    color: #6d6d6d;

    *:focus {
        outline: none;
    }
`

function App() {
    return (
        <StyledApp>
            <BrowserRouter>
                <Switch>
                    <Route exact path="/login" component={LoginPage} />
                    <PrivateRoute path="/" component={HomePage} />
                </Switch>
            </BrowserRouter>
        </StyledApp>
    )
}

export default App
