import React from 'react'
import styled from 'styled-components'
import { LoadingOutlined } from '@ant-design/icons'

const StyledLoadingOutlined = styled(LoadingOutlined)`
    width: 100%;
    align-self: center;
`

export const LoadingState = () => {
    return <StyledLoadingOutlined />
}
