import React from 'react'
import styled from 'styled-components'

const StyledName = styled(({ maxWidth: string, ...props }) => (
    <div {...props} />
))`
    margin: 0 8px 0 8px;

    @media (max-width: ${props => props.maxWidth}) {
        display: none;
    }
`

interface IProps {
    children: React.ReactChild
    maxWidth: string
}

export const Name = (props: IProps) => {
    return <StyledName maxWidth={props.maxWidth}>{props.children}</StyledName>
}
