import React from 'react'
import styled from 'styled-components'
import { ITimeInfo } from '../../models/entities/time-info'
import { ProjectEdit } from './ProjectEdit'
import { setInputList } from '../../utils/index'

const StyledProjectEditContainer = styled.section`
    width: 98%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

interface IProps {
    date: string
    projectInfoList: ITimeInfo[]
}

export const ProjectEditContainer = (props: IProps) => {
    const inputList = setInputList(props.date, props.projectInfoList)

    return (
        <StyledProjectEditContainer>
            {inputList.map((projectInfo: ITimeInfo, i: number) => {
                return <ProjectEdit key={i} projectInfo={projectInfo} />
            })}
        </StyledProjectEditContainer>
    )
}
