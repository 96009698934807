import { httpClient } from './http-client'

export class ProjectsInfoApis {
    static getAllProjects = () => {
        return httpClient.get('projects')
    }

    static getProjectPercent = (
        projectCode: string,
        dateStart: string,
        dateEnd: string,
    ) => {
        return httpClient.get(`project/percent/${projectCode}`, {
            params: {
                projectCode: projectCode,
                dateStart: dateStart,
                dateEnd: dateEnd,
            },
        })
    }
}
