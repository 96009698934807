import React, { useContext } from 'react'
import styled from 'styled-components'
import { ResponsivePie } from '@nivo/pie'
import { observer } from 'mobx-react'
import { MyTimeContext } from '../../contexts/my-time-context'
import { PieChartOutlined } from '@ant-design/icons'
import { LoadingState } from '../components/LoadingState'
import { ErrorState } from '../components/ErrorState'
import { NoDataState } from '../components/NoDataState'
import { themeColor } from '../../models/value-objects/theme-color'

const StyledPieChart = styled.div`
    width: calc(30% - 4px);
    height: 260px;
    background-color: ${themeColor.DARKER};
    border-radius: 2px;
    padding: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-width: 800px) {
        width: 100%;
    }
`

const StyledChartWrapper = styled.div`
    width: 90%;
    height: 240px;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const PieChart = observer(() => {
    const myTimeStore = useContext(MyTimeContext)

    return (
        <StyledPieChart>
            {myTimeStore.pieChartState === 'loading' && <LoadingState />}
            {myTimeStore.pieChartState === 'done' && (
                <StyledChartWrapper>
                    {myTimeStore.totalPercent === 0 && (
                        <NoDataState>
                            <PieChartOutlined />
                        </NoDataState>
                    )}
                    {myTimeStore.totalPercent > 0 && (
                        <ResponsivePie
                            data={myTimeStore.pieChartDataset}
                            colors={{ scheme: 'pastel1' }}
                            margin={{
                                top: 20,
                                left: 20,
                                bottom: 40,
                                right: 20,
                            }}
                            sortByValue={true}
                            enableRadialLabels={false}
                            sliceLabel={function(e) {
                                const percent = Math.round(
                                    (e.value / myTimeStore.totalPercent) * 100,
                                )
                                return percent + '%'
                            }}
                            legends={[
                                {
                                    anchor: 'bottom',
                                    direction: 'row',
                                    translateY: 26,
                                    itemWidth: 55,
                                    itemHeight: 18,
                                    itemTextColor: '#6d6d6d',
                                    symbolSize: 10,
                                    symbolShape: 'square',
                                },
                            ]}
                        />
                    )}
                </StyledChartWrapper>
            )}
            {myTimeStore.pieChartState === 'error' && (
                <ErrorState errorMessage={myTimeStore.errorMessage} />
            )}
        </StyledPieChart>
    )
})
