import React, { useContext, useEffect } from 'react'
import styled from 'styled-components'
import Moment from 'moment'

import { observer } from 'mobx-react'
import { UserInfoContext } from '../../contexts/user-info-context'
import { NameInfo } from './NameInfo'
import { Logo } from '../components/Logo'
import { themeColor } from '../../models/value-objects/theme-color'

const StyledHeader = styled.header`
    width: 100%;
    height: 60px;
    background-color: #ffffff;
    border-bottom: 5px solid ${themeColor.BACKGROUND};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    z-index: 10;
    font-size: 1.05rem;
`

const StyledLogoContainer = styled.div`
    width: 180px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @media (max-width: 1300px) {
        width: 54px;
    }
`

const StyledTitle = styled.div`
    margin: 0 0 5px 0;
    color: ${themeColor.NORMAL};
    font-size: 44px;
    font-weight: bold;
    @media (max-width: 1300px) {
        display: none;
    }
`

const StyledTodayInfo = styled.span`
    text-align: center;
`

export const Header = observer(() => {
    const userInfoStore = useContext(UserInfoContext)

    useEffect(() => {
        userInfoStore.getUserInfo()
    }, [userInfoStore])

    return (
        <StyledHeader>
            <StyledLogoContainer>
                <Logo height="50px" />
                <StyledTitle>JELLY</StyledTitle>
            </StyledLogoContainer>
            <StyledTodayInfo>
                오늘 : {Moment(new Date()).format('YYYY/MM/DD dd')}
            </StyledTodayInfo>
            {NameInfo(
                userInfoStore.userInfo.info1,
                userInfoStore.userInfo.name,
                '1300px',
            )}
        </StyledHeader>
    )
})
