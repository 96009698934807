import React, { useContext } from 'react'
import styled from 'styled-components'
import { Link, useHistory } from 'react-router-dom'
import {
    UserOutlined,
    TeamOutlined,
    LockOutlined,
    CalendarOutlined,
} from '@ant-design/icons'

import { Name } from '../components/Name'
import { observer } from 'mobx-react'
import { UserInfoContext } from '../../contexts/user-info-context'
import { themeColor } from '../../models/value-objects/theme-color'

const StyledNavi = styled.nav`
    width: 180px;
    height: calc(100% - 60px);
    background-color: ${themeColor.BACKGROUND};
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    position: fixed;
    left: 0;
    z-index: 10;

    @media (max-width: 1300px) {
        width: 54px;
    }
`

const StyledMenuButton = styled(({ selected: boolean, ...props }) => (
    <div {...props} />
))`
    height: 44px;
    background-color: ${props =>
        props.selected ? '#ffffff' : 'rgba(0, 0, 0, 0)'};
    border-left: ${props =>
        props.selected
            ? `6px solid ${themeColor.TEXT}`
            : '6px solid rgba(0, 0, 0, 0)'};
    padding: 8px 14px 8px 8px;
    display: flex;
    align-items: center;

    a {
        display: flex;
        align-items: center;
        color: ${props => (props.selected ? themeColor.TEXT : '#ffffff')};
        text-decoration: none;
    }
`

const StyledLogoutButtonWrapper = styled.div`
    height: 100%;
    display: flex;
    align-items: flex-end;
`

const StyledLogoutButton = styled.div`
    width: 100%;
    padding: 8px 14px 8px 14px;
    display: flex;
    align-items: center;
    color: #ffffff;
    cursor: pointer;

    span {
        font-size: 26px;
    }
`

const StyledLink = styled(Link)`
    width: 100%;

    span {
        font-size: 26px;
    }
`

export const Navi = observer(() => {
    const history = useHistory()
    const userInfoStore = useContext(UserInfoContext)

    return (
        <StyledNavi>
            <StyledMenuButton
                selected={userInfoStore.selectedNavi === 'MyProject'}
            >
                <StyledLink
                    to="/"
                    onClick={() => {
                        userInfoStore.setSelectedNavi('MyProject')
                    }}
                >
                    <UserOutlined />
                    <Name maxWidth="1300px">My Project</Name>
                </StyledLink>
            </StyledMenuButton>
            <StyledMenuButton
                selected={userInfoStore.selectedNavi === 'MembersProject'}
            >
                <StyledLink
                    to="/members-project"
                    onClick={() => {
                        userInfoStore.setSelectedNavi('MembersProject')
                    }}
                >
                    <TeamOutlined />
                    <Name maxWidth="1300px">Members&apos; Project</Name>
                </StyledLink>
            </StyledMenuButton>
            <StyledMenuButton
                selected={userInfoStore.selectedNavi === 'ProjectSummary'}
            >
                <StyledLink
                    to="/project-summary"
                    onClick={() => {
                        userInfoStore.setSelectedNavi('ProjectSummary')
                    }}
                >
                    <CalendarOutlined />
                    <Name maxWidth="1300px">Project Summary</Name>
                </StyledLink>
            </StyledMenuButton>
            <StyledLogoutButtonWrapper>
                <StyledLogoutButton
                    onClick={() => {
                        if (window.confirm('로그아웃 하시겠습니까?')) {
                            userInfoStore.setEditingDate('')
                            localStorage.clear()
                            history.push('/login')
                        }
                    }}
                >
                    <LockOutlined />
                    <Name maxWidth="1300px">Logout</Name>
                </StyledLogoutButton>
            </StyledLogoutButtonWrapper>
        </StyledNavi>
    )
})
