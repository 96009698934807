import React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { IDateList } from '../../models/value-objects/date-list'
import { DateBlockHeader } from '../components/DateBlockHeader'
import { ProjectEditContainer } from './ProjectEditContainer'
import { themeColor } from '../../models/value-objects/theme-color'

const StyledDateBlock = styled(({ dateBlockColor: string, ...props }) => (
    <div {...props} />
))`
    width: 13.5%;
    background-color: ${props => props.dateBlockColor};
    border-radius: 2px;
    padding: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width: 800px) {
        width: 100%;
        min-height: 60px;
        height: fit-content;
        margin-bottom: 8px;
    }
`

const getBackgroundColor = (date: string) => {
    const today = moment(new Date()).format('YYYY-MM-DD')
    if (date === today) {
        // 오늘 날짜인 MyTime 블럭이면
        return themeColor.LIGHTER // saturation 80%
    } else {
        return themeColor.DARKER // saturation 25%
    }
}

interface IProps {
    currentDate: Date
    dateList: IDateList
}

export const DateBlock = (props: IProps) => {
    return (
        <StyledDateBlock
            dateBlockColor={getBackgroundColor(props.dateList.date)}
        >
            <DateBlockHeader date={props.dateList.date} />
            <ProjectEditContainer
                date={props.dateList.date}
                projectInfoList={props.dateList.projectInfo}
            />
        </StyledDateBlock>
    )
}
