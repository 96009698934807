import React from 'react'
import { Name } from '../components/Name'
import styled from 'styled-components'
import { themeColor } from '../../models/value-objects/theme-color'

const StyledNameInfo = styled.div`
    padding: 0 16px 0 16px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
`

const StyledIcon = styled.div`
    width: 40px;
    height: 40px;
    background-color: ${themeColor.LIGHTER};
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const NameInfo = (
    nickname: string,
    fullname: string,
    maxWidth: string,
) => {
    return (
        <StyledNameInfo>
            <StyledIcon>
                <span>{nickname === '' ? fullname.slice(0, 1) : nickname}</span>
            </StyledIcon>
            <Name maxWidth={maxWidth}>{fullname}</Name>
        </StyledNameInfo>
    )
}
