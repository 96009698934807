import { ProjectsInfoApis } from '../../apis/projects-info-apis'
import { observable, action } from 'mobx'
import { asyncAction } from 'mobx-utils'
import { ColumnProps } from 'antd/lib/table'
import { NameColumns } from '../../views/fragments/NameColumns'
import { delay } from '../../utils'
import { IProjectPercent } from '../entities/project-percent'
import { IProjectInfo } from '../entities/project-info'

export class ProjectsInfo {
    @observable state: 'loading' | 'done' | 'error' = 'loading'
    @observable projectsList: any[] = []
    @observable projectPercent: IProjectPercent | undefined
    @observable errorMessage: string = ''
    @observable summaryColumns: ColumnProps<object>[] = []
    @observable summaryTableDataList: {
        userId: number
        sum: number
        manMonth: number
        manWeek: number
        manDay: number
    }[] = []

    @action public setSummaryColumns() {
        this.summaryColumns = [
            {
                title: '',
                dataIndex: 'userId',
                width: '25%',
                fixed: true,
                render: (userId: number) => NameColumns(userId),
            },
            { title: 'Sum', dataIndex: 'sum' },
            { title: 'Man Month', dataIndex: 'manMonth' },
            { title: 'Man Week', dataIndex: 'manWeek' },
            { title: 'Man Day', dataIndex: 'manDay' },
        ]
    }

    @action public setSummaryTableDataList(res: IProjectPercent) {
        this.summaryTableDataList = []

        this.summaryTableDataList.push({
            userId: -1,
            sum: res.percentSum,
            manMonth: Math.round(res.percentSum / 5 / 4 / 10) / 10,
            manWeek: Math.round(res.percentSum / 5 / 10) / 10,
            manDay: Math.round(res.percentSum / 10) / 10,
        })

        res.infos.forEach((data: { userId: number; percent: number }) => {
            this.summaryTableDataList.push({
                userId: data.userId,
                sum: data.percent,
                manMonth: Math.round(data.percent / 5 / 4 / 10) / 10,
                manWeek: Math.round(data.percent / 5 / 10) / 10,
                manDay: Math.round(data.percent / 10) / 10,
            })
        })
    }

    @asyncAction public *getProjectPercent(
        projectCode: string,
        dateStart: string,
        dateEnd: string,
    ) {
        this.setSummaryColumns()
        this.state = 'loading'
        try {
            const res = yield ProjectsInfoApis.getProjectPercent(
                projectCode,
                dateStart,
                dateEnd,
            )
            this.setProjectPercent(res.data)
            this.setSummaryTableDataList(res.data)

            delay().then(result => {
                if (result === 'done') this.state = 'done'
            })
        } catch (e) {
            this.errorMessage = e.message
            this.state = 'error'
        }
    }

    @action public setProjectPercent(res: IProjectPercent) {
        this.projectPercent = res
    }

    @asyncAction public *getAllProjects() {
        this.state = 'loading'
        try {
            const res = yield ProjectsInfoApis.getAllProjects()
            this.setProjectsList(res.data)
            this.state = 'done'
        } catch (e) {
            this.state = 'error'
            this.errorMessage = e.message
            console.log(e.message)
        }
    }

    @action public setProjectsList = (res: IProjectInfo[]) => {
        this.projectsList = []

        res.forEach((data: IProjectInfo) => {
            if (
                !this.projectsList.some(
                    currentValue => currentValue.value === data.project,
                )
            ) {
                this.projectsList.push({ value: data.project })
            }
        })
    }
}
