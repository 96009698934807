import React, { useContext, useEffect } from 'react'
import styled from 'styled-components'
import { CalendarOutlined } from '@ant-design/icons'
import { NoDataState } from '../components/NoDataState'
import { observer } from 'mobx-react'
import { MyTimeContext } from '../../contexts/my-time-context'
import { ResponsiveCalendar, CalendarDayData } from '@nivo/calendar'
import moment from 'moment'
import { LoadingState } from '../components/LoadingState'
import { ErrorState } from '../components/ErrorState'
import { themeColor } from '../../models/value-objects/theme-color'
import { MembersTimeContext } from '../../contexts/members-time-context'
import { setDateInfo } from '../../utils'
import { Button } from 'antd'

const StyledCalendarChart = styled.div`
    width: calc(70% - 4px);
    height: 260px;
    background-color: ${themeColor.DARKER};
    margin: 8px 0 8px 0;
    padding: 20px 0 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-width: 800px) {
        width: 100%;
    }
`
const StyledButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.1rem;
`

const StyledButton = styled(Button)`
    margin: 0 16px 0 16px;
    border: none;
    background-color: rgba(0, 0, 0, 0);
    box-shadow: none;

    &:focus,
    &:hover {
        background-color: rgba(0, 0, 0, 0);
    }
`

const StyledChartWrapper = styled.div`
    width: 90%;
    height: 75%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
`

export const CalendarChart = observer(() => {
    const myTimeStore = useContext(MyTimeContext)
    const membersTimeStore = useContext(MembersTimeContext)

    useEffect(() => {
        myTimeStore.getMyMonthlyTime()
    }, [myTimeStore, myTimeStore.currentYear])

    return (
        <StyledCalendarChart>
            {myTimeStore.calendarChartState === 'loading' && <LoadingState />}
            {myTimeStore.calendarChartState === 'done' && (
                <>
                    <StyledButtonContainer>
                        <StyledButton
                            onClick={() => {
                                const prevYear = moment(
                                    myTimeStore.currentDate,
                                ).subtract(1, 'y')

                                setDateInfo(
                                    prevYear.toDate(),
                                    myTimeStore,
                                    membersTimeStore,
                                )
                            }}
                        >
                            {'<'}
                        </StyledButton>
                        <div>{myTimeStore.currentYear}년</div>
                        <StyledButton
                            onClick={() => {
                                const nextYear = moment(
                                    myTimeStore.currentDate,
                                ).add(1, 'y')

                                setDateInfo(
                                    nextYear.toDate(),
                                    myTimeStore,
                                    membersTimeStore,
                                )
                            }}
                        >
                            {'>'}
                        </StyledButton>
                    </StyledButtonContainer>
                    {myTimeStore.calendarChartDataset.length === 0 && (
                        <NoDataState>
                            <CalendarOutlined />
                        </NoDataState>
                    )}
                    {myTimeStore.calendarChartDataset.length > 0 && (
                        <StyledChartWrapper>
                            <ResponsiveCalendar
                                data={myTimeStore.calendarChartDataset}
                                from={moment(myTimeStore.currentDate)
                                    .startOf('year')
                                    .format('YYYY-MM-DD')}
                                to={moment(myTimeStore.currentDate)
                                    .endOf('year')
                                    .format('YYYY-MM-DD')}
                                emptyColor="rgba(0, 0, 0, 0.1)"
                                colors={['#61cdbb', '#fee391', '#f47560']}
                                minValue={100}
                                maxValue={150}
                                monthBorderColor={themeColor.DARKER}
                                monthBorderWidth={5}
                                dayBorderColor={themeColor.DARKER}
                                onClick={(data: CalendarDayData) => {
                                    setDateInfo(
                                        data.date,
                                        myTimeStore,
                                        membersTimeStore,
                                    )
                                }}
                            />
                        </StyledChartWrapper>
                    )}
                </>
            )}
            {myTimeStore.calendarChartState === 'error' && (
                <ErrorState errorMessage={myTimeStore.errorMessage} />
            )}
        </StyledCalendarChart>
    )
})
