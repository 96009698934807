import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import { Input } from 'antd'
import { ITimeInfo } from '../../models/entities/time-info'
import { checkProjectInfoColor, checkInputValueType } from '../../utils'
import { MyTimeContext } from '../../contexts/my-time-context'
import { observer } from 'mobx-react'
import { ProjectsInfoContext } from '../../contexts/projects-info-context'
import { CloseCircleFilled } from '@ant-design/icons'

const StyledEditIndex = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
`

const StyledInputContainer = styled(({ isFocus: boolean, ...props }) => (
    <div {...props} />
))`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
`

const StyledInput = styled(({ color: string, ...props }) => (
    <Input {...props} />
))`
    width: 100%;
    background-color: rgba(0, 0, 0, 0);
    margin: 4px;
    border: rgba(0, 0, 0, 0);
    border-radius: 0px;
    padding: 2px 8px 2px 8px;
    cursor: pointer;
    color: ${props => props.color};

    &:hover {
        background-color: #ffffff;
    }
    &:focus {
        background-color: #ffffff;
        outline: none;
        color: #000000;
    }
`

const StyledCloseCircleFilled = styled(CloseCircleFilled)`
    padding: 8px;
    color: #6d6d6d;
`

interface IProps {
    projectInfo: ITimeInfo
}

export const ProjectEdit = observer((props: IProps) => {
    const myTimeStore = useContext(MyTimeContext)
    const projectsInfoStore = useContext(ProjectsInfoContext)
    const [isFocus, setIsFocus] = useState(false)
    let defaultValue =
        props.projectInfo.project === 'NOTE' || props.projectInfo.project === ''
            ? props.projectInfo.note
            : `${props.projectInfo.project} ${props.projectInfo.percent}`
    const [inputValue, setInputValue] = useState(defaultValue)
    return (
        <StyledEditIndex>
            <StyledInputContainer isFocus={isFocus}>
                <StyledInput
                    color={checkProjectInfoColor(props.projectInfo.project)}
                    value={inputValue}
                    autoComplete="off"
                    onChange={(e: any) => {
                        setInputValue(e.target.value)
                    }}
                    onFocus={() => {
                        setIsFocus(true)
                    }}
                    onBlur={() => {
                        setIsFocus(false)
                        if (inputValue !== defaultValue) {
                            setInputValue(
                                checkInputValueType(
                                    projectsInfoStore,
                                    inputValue,
                                    props.projectInfo,
                                ),
                            )

                            if (inputValue === '' && defaultValue !== '') {
                                myTimeStore.deleteMyTime(props.projectInfo)
                            } else if (
                                inputValue !== '' &&
                                defaultValue === ''
                            ) {
                                myTimeStore.postMyTime(props.projectInfo)
                            } else {
                                myTimeStore.putMyTime(props.projectInfo)
                            }
                        }
                    }}
                    onPressEnter={(e: any) => {
                        e.target.blur()
                    }}
                />
                {inputValue && (
                    <StyledCloseCircleFilled
                        onClick={() => {
                            setInputValue('')
                            props.projectInfo.project = ''
                            props.projectInfo.note = ''
                            props.projectInfo.percent = 0
                            myTimeStore.deleteMyTime(props.projectInfo)
                        }}
                    />
                )}
            </StyledInputContainer>
        </StyledEditIndex>
    )
})
