import React, { useContext, useEffect } from 'react'
import { UserInfoContext } from '../../contexts/user-info-context'
import { observer } from 'mobx-react'
import { SearchInfo } from '../fragments/SearchInfo'
import { SummaryTable } from '../fragments/SummaryTable'
import { SummaryNavi } from '../fragments/SummaryNavi'

export const ProjectSummaryPage = observer(() => {
    const userInfoStore = useContext(UserInfoContext)

    useEffect(() => {
        userInfoStore.setSelectedNavi('ProjectSummary')
    }, [userInfoStore])

    return (
        <>
            <SummaryNavi />
            <SearchInfo />
            <SummaryTable />
        </>
    )
})
