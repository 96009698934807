import React, { useContext, useEffect } from 'react'
import styled from 'styled-components'
import { IDateList } from '../../models/value-objects/date-list'
import { observer } from 'mobx-react'
import { MyTimeContext } from '../../contexts/my-time-context'
import { DateBlock } from './DateBlock'
import { LoadingState } from '../components/LoadingState'
import { ErrorState } from '../components/ErrorState'

const StyledWeeklyChart = styled.div`
    width: 100%;
    min-height: 200px;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
`

export const WeeklyChart = observer(() => {
    const myTimeStore = useContext(MyTimeContext)

    useEffect(() => {
        myTimeStore.getMyWeeklyTime()
    }, [myTimeStore, myTimeStore.weeklyDateList])

    return (
        <StyledWeeklyChart>
            {myTimeStore.weeklyChartState === 'loading' && <LoadingState />}
            {myTimeStore.weeklyChartState === 'done' &&
                myTimeStore.dateList.map((data: IDateList) => {
                    return (
                        <DateBlock
                            key={data.date}
                            currentDate={myTimeStore.currentDate}
                            dateList={data}
                        />
                    )
                })}
            {myTimeStore.weeklyChartState === 'error' && (
                <ErrorState errorMessage={myTimeStore.errorMessage} />
            )}
        </StyledWeeklyChart>
    )
})
