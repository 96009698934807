import { observable, action } from 'mobx'
import moment from 'moment'

export class CurrentInfo {
    @observable currentDate: Date = new Date()
    @observable weeklyDateList: string[] = []
    @observable currentYear: number = moment(new Date()).year()

    @action public setCurrentDateInfo(date: Date) {
        this.currentDate = date
        const dateString = moment(this.currentDate).format('YYYY-MM-DD')

        if (!this.weeklyDateList.includes(dateString)) {
            this.setWeeklyDateList(this.currentDate)
        }

        if (moment(this.currentYear).year() !== this.currentYear) {
            this.currentYear = moment(this.currentDate).year()
        }
    }

    @action public setWeeklyDateList(date: Date) {
        const day = date.getDay()
        const dateStart = day
            ? moment(date).subtract(day - 1, 'd')
            : moment(date).subtract(6, 'd')
        this.weeklyDateList = []
        let weeklyIndex = 0

        while (weeklyIndex < 7) {
            this.weeklyDateList[weeklyIndex] = moment(dateStart)
                .add(weeklyIndex, 'd')
                .format('YYYY-MM-DD')
            weeklyIndex++
        }
    }
}
