import React from 'react'
import styled from 'styled-components'

const StyledNoDataState = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    span {
        font-size: 3rem;
    }

    div {
        height: fit-content;
    }
`

interface IProps {
    children: React.ReactChild
}

export const NoDataState = (props: IProps) => {
    return (
        <StyledNoDataState>
            {props.children} <div>no data</div>
        </StyledNoDataState>
    )
}
